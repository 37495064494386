<template>
  <div>
    <b-form-row>
      <b-col md="6">
        <text-input
          id="donor-first-name"
          name="First Name"
          rules="required|min:3|max:100"
          :text.sync="donor.first_name"
        />
      </b-col>

      <b-col md="6">
        <text-input
          id="donor-last-name"
          name="Last Name"
          rules="required|min:3|max:100"
          :text.sync="donor.last_name"
        />
      </b-col>

      <b-col md="6">
        <text-input
          id="donor-phone"
          name="Phone"
          rules="required|min:8|max:14|phone"
          :text.sync="donor.phone_number"
        />
      </b-col>
      <b-col md="6">
        <text-input
          id="donor-email"
          name="Email"
          type="email"
          rules="required|email"
          :text.sync="donor.email"
        />
      </b-col>
    </b-form-row>

    <location-inputs
      :details="donor"
    />
  </div>
</template>
<script>
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  name: 'DonorInputs',
  components: { TextInput, LocationInputs },
  props: {
    donor: { type: Object, default: () => {} },
  },
}
</script>
<style lang="">

</style>
