<template>
  <b-card>
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent>
        <donor-inputs :donor="donor" />

        <div class="text-right">
          <back class="mr-1" />

          <submit-button
            :handle-submit="handleSubmit"
            :submit="submit"
          />
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import DonorInputs from './DonorInputs.vue'

export default {
  name: 'DonorForm',
  components: { DonorInputs, Back, SubmitButton },
  props: {
    donor: { type: Object, default: () => {} },
    submit: { type: Function, default: null },
  },
}
</script>
<style lang="">

</style>
